import { Route, Routes, ScrollRestoration } from "react-router-dom";
import SelectTransferType from "./send/SelectTransferType";
import SearchUser from "./send/visaro/SearchUser";
import AccountDetails from "./send/bank/AccountDetails";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import Amount from "./send/Amount";
import { APIResponse, Bank, TransferData, TransferType } from "../../@types";
import useBankTransfer from "../../hooks/useBankTransfer";
import { APIError } from "../../helpers/api";
import useVisaroTransfer from "../../hooks/useVisaroTransfer";

type Transfer = {
  bank?: Bank;
  recipientName?: string;
  accountNumber?: string;
  amount?: string;
  transactionPin?: string;
  transferType?: TransferType;
  naration?: string;
  username?: string;
};

interface SendContextProps {
  transfer: Transfer | null;
  setTransfer: React.Dispatch<React.SetStateAction<Transfer | null>>;
  makeTransfer: () => void;
  transfering: boolean;
  transferError: APIError | null;
  isTransferError: boolean;
  transferData: APIResponse<TransferData> | undefined;
}

const SendContext = createContext<SendContextProps | undefined>(undefined);

export function useSendContext() {
  const context = useContext(SendContext);
  if (!context) {
    throw new Error("useSendContext must be used within a SendProvider");
  }
  return context;
}

const Send = () => {
  const [transfer, setTransfer] = useState<Transfer | null>(null);

  const { transfer: bankTransferMutation, ...bank } = useBankTransfer();

  const { transfer: visaroTransferMutation, ...visaro } = useVisaroTransfer();

  const makeTransfer = useCallback(() => {
    if (
      transfer &&
      transfer?.transferType &&
      transfer.amount &&
      transfer.transactionPin
    ) {
      const commonProps = {
        amount: transfer?.amount,
        naration: transfer?.naration || "",
        transaction_pin: transfer?.transactionPin,
      };

      if (transfer.transferType === "inter") {
        if (transfer.accountNumber && transfer.bank) {
          bankTransferMutation({
            ...commonProps,
            to_account_no: transfer?.accountNumber,
            to_bank_code: transfer.bank?.code,
          });
        }
      } else if (transfer?.transferType === "intra") {
        if (transfer?.username) {
          visaroTransferMutation({
            ...commonProps,
            username: transfer?.username!!,
          });
        }
      }
    }
  }, [bankTransferMutation, transfer, visaroTransferMutation]);

  const transferProcessDetails = useMemo(() => {
    if (transfer?.transferType === "inter") {
      return bank;
    }

    return visaro;
  }, [transfer, visaro, bank]);

  return (
    <SendContext.Provider
      value={{
        makeTransfer,
        transfer,
        setTransfer,
        ...transferProcessDetails,
      }}
    >
      <ScrollRestoration />
      <Routes>
        <Route path="/" element={<SelectTransferType />} />
        <Route path="/t/visaro" element={<SearchUser />} />
        <Route path="/t/visaro/amount" element={<Amount />} />
        <Route path="/t/bank" element={<AccountDetails />} />
        <Route path="/t/bank/amount" element={<Amount />} />
      </Routes>
    </SendContext.Provider>
  );
};

export default Send;
