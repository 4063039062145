import { QueryObserverOptions, useQuery } from "react-query";
import { APIResponse, Bank, BeneficiaryEnquiryData } from "../@types";
import { APIError, get } from "../helpers/api";
import { API_URL } from "../../config";

function useBeneficiaryEnquiry(
  beneficiary: {
    accountNumber: string;
    bank: Bank;
  },
  options?: QueryObserverOptions<APIResponse<BeneficiaryEnquiryData>, APIError>
) {
  const {
    isLoading: fetchingBeneficiary,
    isError: isEnquiryError,
    data: beneficiaryEnquiryData,
    refetch: checkBeneficiary,
    error: enquiryError,
  } = useQuery<APIResponse<BeneficiaryEnquiryData>, APIError>(
    `check-${beneficiary.accountNumber}-${beneficiary.bank.code}`,
    () =>
      get(
        API_URL +
          `/beneficial_enquiry?account_no=${beneficiary.accountNumber}&bank_code=${beneficiary.bank.code}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      ),
    {
      enabled: false,
      retry: false,
      ...options,
    }
  );

  return {
    fetchingBeneficiary,
    enquiryError,
    isEnquiryError,
    checkBeneficiary,
    beneficiaryEnquiryData,
  };
}

export default useBeneficiaryEnquiry;
